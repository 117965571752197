
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Api extends Vue {
  private mounted () {
    try {
      const SwaggerUI = require('swagger-ui') // swagger ui has no types
      const openapiConfig = require('@/assets/openapi.json')

      openapiConfig.servers = [
        { url: window.origin + '/api' }
      ]
      SwaggerUI({
        spec: openapiConfig,
        dom_id: 'div#swagger',
        // deepLinking: true,
        presets: [
          SwaggerUI.presets.apis,
          SwaggerUI.SwaggerUIStandalonePreset
        ]
      // layout: 'StandaloneLayout'
      })
    } catch (error) {
      alert(this.$t('error.swagger.not_supported'))
      this.$router.push({ name: 'createSecret' })
    }
  }
}
